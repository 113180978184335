import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState([]);
  const [userDevice, setUserDevice] = useState('User Device');
  const [userIP, setUserIP] = useState('');
  const [userLocation, setUserLocation] = useState('Retrieving...'); // New state for geolocation
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const outputRef = useRef(null);
  const inputRef = useRef(null);
  const [isProfile, setIsProfile] = useState(false);
  const [showMatrix, setShowMatrix] = useState(true);
  const [matrixLines, setMatrixLines] = useState([]);

  // Focus the input field after the matrix animation hides
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMatrix(false); // Hide the matrix animation
      setTimeout(() => {
        inputRef.current?.focus(); // Ensure input is focused after hiding animation
      }, 0); // Delay ensures rendering occurs before focus
    }, 7000); // Matrix animation duration

    return () => clearTimeout(timer);
  }, []);

  // Always focus the input field when component renders and when clicking on the terminal
  useEffect(() => {
    if (!showMatrix) {
      inputRef.current?.focus(); // Ensure input stays focused after matrix is hidden
    }
  }, [showMatrix]);

  // Fetch user device and IP
  useEffect(() => {
    const userAgent = navigator.userAgent;
    setUserDevice(userAgent);

    const fetchIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setUserIP(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIP();
  }, []);

  // Fetch geolocation
  useEffect(() => {
    const fetchGeolocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation(`Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`);
          },
          (error) => {
            console.error('Error fetching geolocation:', error);
            setUserLocation('****************');
          }
        );
      } else {
        setUserLocation('Geolocation not supported');
      }
    };

    fetchGeolocation();
  }, []);

  // Matrix animation setup
  useEffect(() => {
    const linesCount = Math.floor(window.innerWidth / 20); // Adjust number of lines
    const newMatrixLines = Array.from({ length: linesCount * 5 }, (_, i) => { // Increased lines count for continuous flow
      return {
        left: `${(i % linesCount) * 20}px`,
        duration: `${Math.random() * 2 + 2}s`, // Randomize speed
        content: Array.from({ length: 20 }, () => Math.floor(Math.random() * 10)).join('') // Generate random numbers
      };
    });
    setMatrixLines(newMatrixLines);
  }, []);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const command = input.trim().toLowerCase();
      let newOutput = [];

      if (isProfile) {
        if (command === 'facebook') {
          newOutput = [
            <span>
              Here's a link to my Facebook account: <a href="https://www.facebook.com/prabachanbastola" target="_blank" rel="noopener noreferrer">https://www.facebook.com/prabachanbastola</a>
            </span>
          ];
        } else if (command === 'instagram') {
          newOutput = [
            <span>
              Here's a link to my Instagram account: <a href="https://www.instagram.com/prabachanbastola/" target="_blank" rel="noopener noreferrer">https://www.instagram.com/prabachanbastola/</a>
            </span>
          ];
        } else if (command === 'linkedin') {
          newOutput = [
            <span>
              Here's a link to my LinkedIn profile: <a href="https://www.linkedin.com/in/prabachan/" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/in/prabachan/</a>
            </span>
          ];
        } else if (command === 'back') {
          setIsProfile(false);
          newOutput = ["Returning to main prompt..."];
        } else {
          newOutput = [`'${input}' is not recognized in the profile directory`];
        }
      } else {
        if (command === 'clear' || command === 'clean' || command === 'cls') {
          setOutput([]);
          setInput('');
          return;
        } else if (command === 'help' || command === '?') {
          newOutput = ["Available commands: hello, clean, profile, whois prabachan, contact, mail, exit"];
        } else if (command === 'hey' || command === 'hello' || command === 'hi' || command === 'yo') {
          newOutput = [`Hello ${userIP}, ${getGreeting()}!! How are you today? You can continue terminal with other commands. Have a good day! `];
        } else if (command === 'contact' || command === 'mail') {
          newOutput = [
            <span>
              You can contact me through my mail address: <a href="mailto:mail@prabachan.com">mail@prabachan.com</a> || <a href="mailto:contact@prabachan.com">contact@prabachan.com</a>
            </span>
          ];
        } else if (command === 'profile' || command === 'profiles') {
          newOutput = ["Available codes in the profile directory: facebook, instagram, linkedin, back", "Type 'facebook', 'instagram', or 'linkedin' for more information."];
          setIsProfile(true);
          setOutput((prev) => [...prev, `P:>\\ profile\\`]); // Add profile directory prompt to output
        } else if (command === 'exit') {
          handleClose();
        } else if (command === 'whois prabachan' || command === `whois`) {
          newOutput = [
            "Prabachan Bastola is an IT enthusiast who loves to work with terminals, computers, techs.. you can visit my socials by typing command 'profile'"
          ];
        } else {
          newOutput = [`'${input}' is not recognized as an internal or external command, type 'help' or ? for help menu`];
        }
      }

      setOutput((prev) => [...prev, `P:>\\ ${isProfile ? 'profile\\' : ''}${input}`, ...newOutput]);
      setCommandHistory([...commandHistory, input]);
      setHistoryIndex(-1);
      setInput('');
    }

    if (e.key === 'ArrowUp') {
      if (historyIndex < commandHistory.length - 1) {
        const newIndex = historyIndex + 1;
        setInput(commandHistory[commandHistory.length - 1 - newIndex]);
        setHistoryIndex(newIndex);
      }
    } else if (e.key === 'ArrowDown') {
      if (historyIndex > 0) {
        const newIndex = historyIndex - 1;
        setInput(commandHistory[commandHistory.length - 1 - newIndex]);
        setHistoryIndex(newIndex);
      } else {
        setInput('');
        setHistoryIndex(-1);
      }
    }
  };

  const handleClose = () => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      alert("Please close this tab manually on your mobile browser.");
    } else {
      window.close();
    }
  };

  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [output]);

  const handleTerminalClick = () => {
    inputRef.current.focus();
  };

  const getGreeting = () => {
    const hours = new Date().getHours();
    if (hours < 12) return 'Good morning';
    if (hours < 18) return 'Good afternoon';
    return 'Good evening';
  };

  return (
    <div className="App" onClick={handleTerminalClick}>
      {showMatrix && (
        <div className="matrix-background">
          {matrixLines.map((line, index) => (
            <div
              key={index}
              className="matrix-line"
              style={{
                left: line.left,
                animationDuration: line.duration,
              }}
            >
              {line.content}
            </div>
          ))}
        </div>
      )}
      <div className="terminal" style={{ display: showMatrix ? 'none' : 'block' }}>
      <div className="header">
          {getGreeting()}, {userIP}! <br /> Welcome to my terminal! <br /> For some privacy reason I am tracking down your device information, for instance. <br />
          <br /> This is your device info: <br /> {userDevice} <br />
          <br /> This is your IP Address: {userIP} 
          <br /> This is your geolocation: {userLocation} <br />  {}
        </div>
        <div className="copyright">
          <br></br>(c) Prabachan Bastola. All rights reserved.
        </div>
        <div className="close-button" onClick={handleClose}>X</div>
        <div className="output" ref={outputRef}>
          {output.map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
        <div className="input-area">
          <span className="input-prompt">P:\{isProfile ? '\\ profile\\' : ''}&gt;</span>
          <input
            ref={inputRef}
            type="text"
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        </div>
      </div>
    </div>
  );
}

export default App;
